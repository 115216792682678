import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import logoImg from "../../assets/images/logo.png"
import { v4 as uuidv4 } from "uuid"
import { FaEnvelope, FaHome, FaPhoneAlt } from "react-icons/fa"
import isoImg from "../../assets/images/iso-logo.png"
import { Link } from "gatsby"

const footerLinks = [
  {
    id: uuidv4(),
    name: "Company",
    link: "/about",
  },
  {
    id: uuidv4(),
    name: "Family Culture",
    link: "/family-culture",
  },
  {
    id: uuidv4(),
    name: "Api Classification",
    link: "/api-classification",
  },
  {
    id: uuidv4(),
    name: "Additives",
    link: "/additives",
  },
  {
    id: uuidv4(),
    name: "Partnership",
    link: "/partnership",
  },
  {
    id: uuidv4(),
    name: "Contact Us",
    link: "/contact",
  },
]

const date = new Date()
const year = date.getFullYear()

const Footer = () => {
  return (
    <footer className="footer">
      <Container className="footer-main-container">
        <Row>
          <Col
            md={8}
            lg={4}
            data-sal-duration="1000"
            data-sal="slide-left"
            data-sal-easing="ease"
          >
            <img src={logoImg} alt="" className="footer-brand img-fluid mb-3" />
            <p className="mb-0">
              We are an established company with Manufacturing and Blending unit
              in Rajkot. We started in a small way but with Dedication,
              Sincerity and Quality Persistence.
            </p>
          </Col>
          <Col
            md={4}
            lg={2}
            data-sal-duration="1000"
            data-sal="slide-left"
            data-sal-easing="ease"
            data-sal-delay="200"
          >
            <h4 className="mb-4 footer-heading">Quick Links</h4>
            <ul className="footer-menu">
              {footerLinks.map(el => (
                <li key={el.id}>
                  <Link to={el.link}>{el.name}</Link>
                </li>
              ))}
            </ul>
          </Col>
          <Col
            md={6}
            lg={4}
            data-sal-duration="1000"
            data-sal="slide-left"
            data-sal-easing="ease"
            data-sal-delay="400"
          >
            <h4 className="mb-4 footer-heading">Get In Touch</h4>
            <div className="contact-info-box c-i-address">
              <span className="icon-box">
                <FaHome />
              </span>
              <span>
                210, 2nd Floor, Sanjay Commercial Complex,Nr.Doctor House,
                Rajputpara, Rajkot-360001
              </span>
            </div>
            <a
              href="mailto:info@shanpetro.com"
              className="contact-info-box c-i-mail"
            >
              <span className="icon-box">
                <FaEnvelope />
              </span>
              <span>info@shanpetro.com</span>
            </a>
            <a href="tel:+919374404052" className="contact-info-box c-i-call">
              <span className="icon-box">
                <FaPhoneAlt />
              </span>
              <span>+91 93744 04052</span>
            </a>
          </Col>
          <Col
            md={6}
            lg={2}
            data-sal-duration="1000"
            data-sal="slide-left"
            data-sal-easing="ease"
            data-sal-delay="600"
          >
            <div className="iso-box">
              <div className="img-box">
                <img src={isoImg} alt="" />
              </div>
              <h5>AN ISO 9001:2015 CERTIFIED COMPANY</h5>
            </div>
          </Col>
        </Row>
      </Container>
      <div className="footer-credit">
        <Container>
          <p>
            <span>Copyright © {year} Shan Petro All Rights Reserved.</span>{" "}
            <span>
              <a href="https://coreniq.com/" target="_blank">
                Developed By <span>Coreniq</span>
              </a>
            </span>
          </p>
        </Container>
      </div>
    </footer>
  )
}

export default Footer
