exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-additives-js": () => import("./../../../src/pages/additives.js" /* webpackChunkName: "component---src-pages-additives-js" */),
  "component---src-pages-api-classification-js": () => import("./../../../src/pages/api-classification.js" /* webpackChunkName: "component---src-pages-api-classification-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-family-culture-js": () => import("./../../../src/pages/family-culture.js" /* webpackChunkName: "component---src-pages-family-culture-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-partnership-js": () => import("./../../../src/pages/partnership.js" /* webpackChunkName: "component---src-pages-partnership-js" */),
  "component---src-templates-project-details-js": () => import("./../../../src/templates/ProjectDetails.js" /* webpackChunkName: "component---src-templates-project-details-js" */)
}

