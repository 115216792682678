import React, { Fragment } from "react"
import { Accordion } from "react-bootstrap"
import AccordionItem from "./AccordionItem"
import { StaticQuery, graphql, Link } from "gatsby"
import { FaBoxes } from "react-icons/fa"

// function to return inner menus with or without accordion
function MenuCrawler(products, parentId) {
  return (
    <Accordion defaultActiveKey="0" flush className="sidebar-accordion">
      {products.map(product => {
        if (product.node.parentId === parentId) {
          if (product.node.wpChildren.nodes.length > 0) {
            return (
              <AccordionItem
                key={product.node.id}
                toggleChildren={<span>{product.node.title}</span>}
                eKey={product.node.id}
              >
                {MenuCrawler(products, product.node.id)}
              </AccordionItem>
            )
          } else {
            return (
              <Link
                to={`/products/${product.node.slug}`}
                key={product.node.id}
                className="sidebar-item"
              >
                <span>{product.node.title}</span>
              </Link>
            )
          }
        }
      })}
    </Accordion>
  )

  // if (product.categories) {
  //   return (
  //     <Accordion defaultActiveKey="0" flush className="sidebar-accordion">
  //       {product.categories.map((p, idx) => {
  //         if (p.categories) {
  //           return (
  //             <AccordionItem
  //               key={idx}
  //               toggleChildren={<span>{p.name}</span>}
  //               eKey={idx}
  //             >
  //               {MenuCrawler(p, idx)}
  //             </AccordionItem>
  //           )
  //         } else {
  //           return (
  //             <a href="" className="sidebar-item">
  //               <span>{p}</span>
  //             </a>
  //           )
  //         }
  //       })}
  //     </Accordion>
  //   )
  // } else {
  //   return (
  //     <a href="" className="sidebar-item">
  //       <span>{product.name}</span>
  //     </a>
  //   )
  // }
}

const ProductsMenu = ({ isActive }) => {
  return (
    <StaticQuery
      query={graphql`
        query ProductsLinksQuery {
          allWpProduct(sort: { fields: title }) {
            edges {
              node {
                id
                title
                wpChildren {
                  nodes {
                    id
                  }
                }
                parentId
                slug
              }
            }
          }
        }
      `}
      render={data => {
        const products = data.allWpProduct.edges
        return (
          <AccordionItem
            isActive={isActive}
            toggleChildren={
              <Fragment>
                <span className="icon-box me-2">
                  <FaBoxes />
                </span>
                <span>Our Products</span>
              </Fragment>
            }
            eKey={1}
          >
            <Accordion
              defaultActiveKey="0"
              flush
              className="sidebar-accordion products-main-accordion"
            >
              {products.map((product, id) => {
                if (!product.node.parentId) {
                  return (
                    <AccordionItem
                      key={id}
                      toggleChildren={<span>{product.node.title}</span>}
                      eKey={id}
                    >
                      {MenuCrawler(products, product.node.id)}
                    </AccordionItem>
                  )
                }
              })}
            </Accordion>
          </AccordionItem>
        )
      }}
    />
  )
}

export default ProductsMenu

/* <Accordion.Item eventKey="1">
<CustomToggle eventKey="1">
  <span className="icon-box me-2">
    {' '}
    <Home />{' '}
  </span>{' '}
  <span>Our Products</span>
</CustomToggle>
<Accordion.Body>
  <Accordion defaultActiveKey="0" flush className="sidebar-accordion" >
    <Accordion.Item eventKey="0">
      <CustomToggle eventKey="0">
        <span>Gypsol</span>
      </CustomToggle>
      <Accordion.Body>
        <a href="" className="sidebar-item">
          <span>Bike Engine Oil</span>
        </a>
        <a href="" className="sidebar-item">
          <span>Deo</span>
        </a>
        <a href="" className="sidebar-item">
          <span>Gear</span>
        </a>
        <a href="" className="sidebar-item">
          <span>Grease And Coolant</span>
        </a>
        <a href="" className="sidebar-item">
          <span>Industrial</span>
        </a>
      </Accordion.Body>
    </Accordion.Item>
    <Accordion.Item eventKey="1">
      <CustomToggle eventKey="1">
        <span>Titanic</span>
      </CustomToggle>
      <Accordion.Body>
        <Accordion defaultActiveKey="0" flush className="sidebar-accordion" >
          <Accordion.Item eventKey="0">
            <CustomToggle eventKey="0">
              <span>Bike Engine Oil</span>
            </CustomToggle>
            <Accordion.Body>
              <a href="" className="sidebar-item">
                <span>Break</span>
              </a>
              <Accordion
                defaultActiveKey="0"
                flush
                className="sidebar-accordion"
              >
                <AccordionItem toggleChildren={<span>Engine</span>}>
                <Accordion
                      defaultActiveKey="0"
                      flush
                      className="sidebar-accordion"
                      id="productsTitanicEngineAccordion"
                    >
                      <AccordionItem toggleChildren={<span>Mineral Grade</span>}>
                        <a href="" className="sidebar-item">
                          <span>Beat 4T</span>
                        </a>
                        <a href="" className="sidebar-item">
                          <span>Labrez 4T</span>
                        </a>
                        <a href="" className="sidebar-item">
                          <span>SX4 4T</span>
                        </a>
                      </AccordionItem>
                    </Accordion>
                </AccordionItem>
              </Accordion>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion
            defaultActiveKey="1"
            flush
            className="sidebar-accordion"
            id="productsTitanicCarEoAccordion"
          >
            <Accordion.Item eventKey="1">
              <CustomToggle eventKey="1">
                <span>Car Engine Oil</span>
              </CustomToggle>
              <Accordion.Body>
                <Accordion
                  defaultActiveKey="0"
                  flush
                  className="sidebar-accordion"
                  id="productsTitanicCarEoAccordion"
                >
                  <Accordion.Item eventKey="0">
                    <CustomToggle eventKey="0">
                      <span>Axel Gear Fluids</span>
                    </CustomToggle>
                    <Accordion.Body>
                      <a href="" className="sidebar-item">
                        <span>Crown Axel 85w-140 Api Gl-5</span>
                      </a>
                      <a href="" className="sidebar-item">
                        <span>Syn Gear 75W-90 GL-5(Semi Synthetic)</span>
                      </a>
                      <a href="" className="sidebar-item">
                        <span>Synchro Gear 80W-90 API GL-5</span>
                      </a>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                <a href="" className="sidebar-item">
                  <span>Labrez 4T</span>
                </a>
                <a href="" className="sidebar-item">
                  <span>SX4 4T</span>
                </a>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <a href="" className="sidebar-item">
            <span>Gas Engine Oils</span>
          </a>
          <a href="" className="sidebar-item">
            <span>Gear Oil</span>
          </a>
          <a href="" className="sidebar-item">
            <span>Grease And Coolant</span>
          </a>
          <a href="" className="sidebar-item">
            <span>HCVO</span>
          </a>
          <a href="" className="sidebar-item">
            <span>Industrial Fluids</span>
          </a>
          <a href="" className="sidebar-item">
            <span>Tractor Oil</span>
          </a>
          <a href="" className="sidebar-item">
            <span>Utility Vehicle</span>
          </a>
        </Accordion>
      </Accordion.Body>
    </Accordion.Item>
  </Accordion>
</Accordion.Body>
</Accordion.Item> */
