import { Link } from "gatsby"
import React from "react"
import { Navbar } from "react-bootstrap"
import { Menu } from "react-feather"
import logo from "../../assets/images/logo.png"
import { FaEnvelope, FaFacebookF, FaInstagram } from "react-icons/fa"
const AppNavbar = ({ toggleSidebar }) => {
  return (
    <Navbar expand={false} className="app-navbar">
      <div
        data-sal-duration="1000"
        data-sal-delay="100"
        data-sal="slide-left"
        data-sal-easing="ease"
      >
        <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={toggleSidebar}>
          <Menu />
        </Navbar.Toggle>
      </div>
      <Link
        to="/"
        className="navbar-brand mx-auto"
        data-sal-duration="1000"
        data-sal-delay="100"
        data-sal="slide-down"
        data-sal-easing="ease"
      >
        <img src={logo} alt="shanpetro logo" className="img-fluid" />
      </Link>
      <div
        className="social-box"
        data-sal-duration="1000"
        data-sal-delay="100"
        data-sal="slide-right"
        data-sal-easing="ease"
      >
        <a href="https://www.facebook.com/SHANPETRO/" target="_blank" rel="noreferrer">
          <FaFacebookF />
        </a>
        <a href="https://instagram.com/shanpetro_oil?utm_medium=copy_link" target="_blank" rel="noreferrer">
          <FaInstagram/>
        </a>
        <a href="mailto:info@shanpetro.com" >
          <FaEnvelope />
        </a>
      </div>
    </Navbar>
  )
}

export default AppNavbar
