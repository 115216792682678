import React from 'react';
import { Accordion } from 'react-bootstrap';
import CustomToggle from './CustomToggle';

const AccordionItem = ({ eKey, toggleChildren, children,isActive }) => {
  return (
    <Accordion.Item eventKey={eKey}>
      <CustomToggle isActive={isActive} eventKey={eKey}>{toggleChildren}</CustomToggle>
      <Accordion.Body>{children}</Accordion.Body>
    </Accordion.Item>
  );
};

export default AccordionItem;
