import React, { useEffect, useState } from "react"
import { Accordion } from "react-bootstrap"
import { X } from "react-feather"
import SimpleBar from "simplebar-react" 
import CustomToggle from "./CustomToggle"
import ProductsMenu from "./ProductsMenu"  
import TransitionLink from "gatsby-plugin-transition-link"
import { useLocation } from "@reach/router";
import { FaCity, FaEnvelopeOpenText, FaHandshake, FaHome } from "react-icons/fa";
import { FaEnvelope, FaFacebookF, FaInstagram } from "react-icons/fa"
const Sidebar = ({ sidebarOpen, toggleSidebar }) => {
  const location =  useLocation();
  const [activeLink,setActiveLink] = useState(1);
// console.log(location);
  const companyPages = [
    '/about',
    '/family-culture',
    '/api-classification',
    '/additives'
  ]

  const setActiveClass = (location) => {
    if (companyPages.includes(location.pathname)) {
      setActiveLink(2);
    }else if(location.pathname.includes('/products')){
      setActiveLink(3);
    }
    else if(location.pathname.includes('/partnership')){
      setActiveLink(4);
    }
    else if(location.pathname.includes('/contact')){
      setActiveLink(5);
    }
    else{
      setActiveLink(1);
    }
  }

  useEffect(() => {
     setActiveClass(location);
  }, [location])

  return (
    <div className={`sidebar ${sidebarOpen && "active"}`}>
      <button className="sidebar-toggler" onClick={toggleSidebar}>
        <X />
      </button>
      <div className="sidebar-content-wrapper">
        <SimpleBar
          className="sidebar-simplebar-wrapper"
          autoHide={false}
          style={{ maxHeight: "100%" }}
        >
          <TransitionLink
            exit={{
              length: 0.55,
            }}
            entry={{
              delay: 0.2,
            }}
            to="/"
            className={`sidebar-item ${activeLink === 1 ? "active" : ''} `}
          >
            <span className="icon-box me-2">
              <FaHome/>
            </span>
            <span>Home</span>
          </TransitionLink>
          <Accordion flush className="sidebar-accordion">
            <Accordion.Item eventKey="0">
              <CustomToggle eventKey="0" isActive={activeLink === 2 ? true : false}>
                <span className="icon-box me-2">
                  <FaCity/>
                </span>
                <span>Company</span>
              </CustomToggle>
              <Accordion.Body>
                <TransitionLink
                  exit={{
                    length: 0.55,
                  }}
                  entry={{
                    delay: 0.2,
                  }}
                  to="/about"
                  className="sidebar-item"
                  
                >
                  <span>About Us</span>
                </TransitionLink>
                <TransitionLink
                  exit={{
                    length: 0.7,
                  }}
                  entry={{
                    delay: 0.2,
                  }}
                  to="/family-culture"
                  className="sidebar-item"
                >
                  <span>Family Culture</span>
                </TransitionLink>
                <Accordion flush className="sidebar-accordion">
                  <Accordion.Item eventKey="0">
                    <CustomToggle eventKey="0">
                      <span>Lubricant Fundamentals</span>
                    </CustomToggle>
                    <Accordion.Body>
                      <TransitionLink
                        to="/api-classification"
                        className="sidebar-item"
                      >
                        <span>API Classification</span>
                      </TransitionLink>
                      <TransitionLink to="/additives" className="sidebar-item">
                        <span>Additives</span>
                      </TransitionLink>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Accordion.Body>
            </Accordion.Item>
            <ProductsMenu isActive={activeLink === 3 ? true : false} />
          </Accordion>
          <TransitionLink to="/partnership"  className={`sidebar-item ${activeLink === 4 ? "active" : ''} `}>
            <span className="icon-box me-2">
              <FaHandshake/>
            </span>
            <span>Partnership</span>
          </TransitionLink>
          <TransitionLink
            exit={{
              length: 0.55,
            }}
            entry={{
              delay: 0.2,
            }}
            to="/contact"
            className={`sidebar-item ${activeLink === 5 ? "active" : ''} `}
          >
            <span className="icon-box me-2">
              <FaEnvelopeOpenText/>
            </span>
            <span>Contact Us</span>
          </TransitionLink>
        </SimpleBar>
      </div>
      <div
        className="social-box social-box-sidebar"
        data-sal-duration="1000"
        data-sal-delay="100"
        data-sal="slide-right"
        data-sal-easing="ease"
      >
         <a href="https://www.facebook.com/SHANPETRO/" target="_blank" rel="noreferrer">
          <FaFacebookF />
        </a>
        <a href="https://instagram.com/shanpetro_oil?utm_medium=copy_link" target="_blank" rel="noreferrer">
          <FaInstagram/>
        </a>
        <a href="mailto:info@shanpetro.com" >
          <FaEnvelope />
        </a>
      </div>
    </div>
  )
}

export default Sidebar
