import React, { useEffect, useState } from "react"
import Sidebar from "../sidebar/Sidebar"
// Import Swiper styles
import "swiper/css"
import "swiper/css/navigation"
import "../../assets/styles/App.scss"
import "simplebar/dist/simplebar.min.css"
import Navbar from "../header/Navbar"
import Footer from "../footer/Footer"
import { globalHistory } from "@reach/router"
import { AnimateSharedLayout } from "framer-motion"

const MainLayout = ({ children }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [loader, setLoader] = useState(false)

  const toggleLoader = () => {
    setLoader(true)
    setTimeout(() => {
      setLoader(false)
    }, 2500)
  }

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen)
  }
  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action === "PUSH") {
        toggleLoader()
        setSidebarOpen(false)
      }
    })
  }, [setSidebarOpen])

  useEffect(() => {
    toggleLoader()
  }, [])

  return (
    <div className="main-layout">
      {loader && (
        <div className="loader-wrapper">
          <div className="loader-box">
            <div class="sk-folding-cube">
              <div class="sk-cube1 sk-cube"></div>
              <div class="sk-cube2 sk-cube"></div>
              <div class="sk-cube4 sk-cube"></div>
              <div class="sk-cube3 sk-cube"></div>
            </div>
          </div>
        </div>
      )}
      <Navbar toggleSidebar={toggleSidebar} />
      <Sidebar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
      <AnimateSharedLayout>
        <main
          className={`main-content-wrapper ${sidebarOpen && "overflow-hidden"}`}
        >
          {children}
        </main>
      </AnimateSharedLayout>
      <Footer />
    </div>
  )
}

export default MainLayout
