import React, { useContext } from 'react';
import { AccordionContext, useAccordionButton } from 'react-bootstrap';

export default function CustomToggle({ children, eventKey,callback,isActive  }) {
  const { activeEventKey } = useContext(AccordionContext);
  // const decoratedOnClick = useAccordionButton(eventKey, () => console.log('totally custom!'));
  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey),
  );
  const isCurrentEventKey = activeEventKey === eventKey;
  return (
    <button type="button" className={`sidebar-item sidebar-item-accordion ${isActive && 'active'} ${ isCurrentEventKey && "accordion-btn-active"}`} onClick={decoratedOnClick}>
      {children}
    </button>
  );
}